<div class="card padding-card t-align-l no-radius border">
    <div class="card-body">
        <h5 class="card-title mb-4">Property Highlights</h5>
        <button (click)="addSwatch()" class="btn btn-primary btn-lg">Add New</button>
        <div class="row">
            <div class="form-group col-md-12" *ngFor="let swatch of swatchList; let i = index;">
                <label for="location">Enter Highlights</label>
                <div>
                    <button class="btn btn-warning btn-lg mb-2 float-right mr-2" (click)=" deleteSwatch(i)">delete</button>

                </div>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location"
                        [(ngModel)]="swatchList[i].swatchName">
                </div>
            </div>
        </div>

    </div>
   

</div>
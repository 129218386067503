import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
export interface IMenuOption {
  label: string;
  active: boolean;
  pageView:number;
  sideMenuicon: string
}
@Component({
  selector: 'app-project-sidebar',
  templateUrl: './project-sidebar.component.html',
  styleUrls: ['./project-sidebar.component.css']
})
export class ProjectSidebarComponent implements OnInit {

  menuOptions: Array<IMenuOption> = [
    {
      label: 'Location',
      active: true,
      pageView:1,
      sideMenuicon: 'ri-map-pin-line'
    },
    {
      label: 'Project Details',
      active: false,
      pageView:2,
      sideMenuicon: "ri-information-2-line"
    },
    {
      label: ' Features',
      active: false,
      pageView:3,
      sideMenuicon: 'ri-file-settings-line'


    },
    {

      label: 'Gallery',
      active: false,
      pageView:4,
      sideMenuicon: "ri-image-add-fill"
    },
    {

      label: 'Floor Plan',
      active: false,
      pageView:5,
      sideMenuicon: "ri-image-circle-line"
    },
    {

      label: 'Brochure',
      active: false,
      pageView:6,
      sideMenuicon: "ri-book-read-line"

    },
    {

      label: 'Project Highlights',
      active: false,
      pageView:7,
      sideMenuicon: "ri-hotel-line"

    },
    {

      label: 'Location Highlights',
      active: false,
      pageView:8,
      sideMenuicon: "ri-map-pin-range-line"

    },
    {

      label: 'Specifications',
      active: false,
      pageView:9,
      sideMenuicon: "ri-booklet-line"

    }
    // {

    //   label: 'Publish',
    //   active: false,
    //   pageView:10,
    //   sideMenuicon: 'ri-group-3-line'

    // }
    
    
  ]
  subMenuOpenFlag: boolean = false;
  pageView:number = 1;
  constructor(private router: Router,
    private lookupModel: RsbLookupModel,
    private rsbService: RsbService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.rsbService.data$.subscribe(data => {
      this.pageView = data;
      this.openMenuItem(this.pageView)
    });
  }
  openMenuItem(pageView:number): void {
    this.menuOptions.map((obj) => {
      if(obj.pageView === pageView) {
        obj.active = true;
      } else {
        obj.active = false;
      }
    })
  }

  home(): void {
    this.router.navigate([''])
  }

}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
export interface IMenuOption {
  label: string;
  active: boolean;
  pageView:number;
  sideMenuicon: string
}

@Component({
  selector: 'app-company-sidebar',
  templateUrl: './company-sidebar.component.html',
  styleUrls: ['./company-sidebar.component.css']
})
export class CompanySidebarComponent implements OnInit {
  menuOptions: Array<IMenuOption> = [
    {
      label: 'CompanyInfo',
      active: true,
      pageView:1,
      sideMenuicon: "ri-information-2-line"
    },
    {
      label: 'Company Images',
      active: false,
      pageView:2,
      sideMenuicon: "ri-file-image-line"
    },
    {
      label: 'Add Successfull Projects',
      active: false,
      pageView:3,
      sideMenuicon: "ri-building-2-line"


    },
    {

      label: 'Success Projects Images',
      active: false,
      pageView:4,
      sideMenuicon:"ri-contract-line"
    },
    {

      label: 'Add Ongoing Projects',
      active: false,
      pageView:5,
      sideMenuicon: 'ri-community-line'
    },
    {

      label: 'Ongoing Project Images',
      active: false,
      pageView:6,
      sideMenuicon: 'ri-building-line'

    }
  ]
  subMenuOpenFlag: boolean = false;
  pageView:number = 1;
  constructor(private router: Router,
    private lookupModel: RsbLookupModel,
    private rsbService: RsbService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.rsbService.data$.subscribe(data => {
      this.pageView = data;
      this.openMenuItem(this.pageView)
    });
  }
  openMenuItem(pageView:number): void {
    this.menuOptions.map((obj) => {
      if(obj.pageView === pageView) {
        obj.active = true;
      } else {
        obj.active = false;
      }
    })
  }
  home(): void {
    this.router.navigate([''])
  }
}
